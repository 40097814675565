.bm-burger-button {
    position: fixed;
    width: 40px;
    height: 40px;
    right: 20px;
    top: 20px;
    float: right;
}

.bm-burger-bars {
    background-color: #000;
}

.bm-cross-button {
    width: 24px;
    height: 24px;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-menu {
    background-color: #373a47;
    /* padding: 2.5em 1.5em 0; */
    padding-top: 24px;
    padding-left: 1em;
    font-size: 1.15em;
}

.bm-item {
    display: block;
    cursor: pointer;
    color: #bdc3c7;
    text-decoration: none;
    padding-bottom: 15px;
}

.bm-item:hover {
    color: #ffffff;
}