.icon-base {
  display: inline-block;
  border: none;
  background-color: transparent;
  width: 25px;
  height: 25px;
}

.icon-base svg {
   width: 25px;
  height: 25px;
}