.close-button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.close-button svg {
  width:20px;
  height: 20px;
}

