.color-picker {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 0.5em;
  margin-left: 0.5em;
  border:1px solid #000;
  border-width: 1px;
  border-radius: 8px;
  background-color: #eee;
}

.color-picker > .react-colorful {
  position: relative;
  top: 0;
  left: 0;
}

.color-picker .react-colorful__saturation {
  border-radius: 0;
}

.color-picker-draggable-header {
  cursor: move;
  color: #65686B;
  font-size: large;
  font-weight: 500;
  padding: 12px;
  border-radius: 8px 8px 0 0;
}