html,
body,
#root,
#page-wrap,
canvas {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}